import { render, staticRenderFns } from "./terminal-icon.vue?vue&type=template&id=bd00646e&scoped=true&"
import script from "./terminal-icon.ts?vue&type=script&lang=ts&"
export * from "./terminal-icon.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd00646e",
  null
  
)

export default component.exports