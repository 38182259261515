import { render, staticRenderFns } from "./card-icon.vue?vue&type=template&id=88fc8c82&scoped=true&"
import script from "./card-icon.ts?vue&type=script&lang=ts&"
export * from "./card-icon.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88fc8c82",
  null
  
)

export default component.exports